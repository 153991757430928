import React from 'react';
import Button from './Button';

function JobCard({ job, openModal }) {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md relative group transition-all duration-300 hover:shadow-xl">
      <div className="mb-4">
        <h3 className="text-xl font-bold text-gray-900">{job.title}</h3>
        <div className="flex items-center">
          <p className="text-md text-gray-600 flex items-center">
            {job.company}
            <svg className="w-5 h-5 ml-2 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
            </svg>
          </p>
        </div>
      </div>
      <div className="space-y-2 text-gray-600">
        <p>{job.location}</p>
        <p>{job.salary}</p>
        <p>{job.type}</p>
        <p className="text-sm">{job.recruiterStatus}</p>
      </div>
      <p className="mt-4 text-sm text-gray-500">{job.applyBefore}</p>
      <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-lg">
        <div className="space-x-4">
          <Button onClick={openModal} className="bg-green-500 text-white hover:bg-green-600">Apply</Button>
        </div>
      </div>
    </div>
  );
}

export default JobCard;