import React, { useState } from 'react';
import Button from './Button';
import LazyImage from './LazyImage';

function Header({ openSignUpModal, openJobListingModal, isMobile }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      const headerOffset = 80; // Adjust this value based on your header height
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });

      // Update URL without page reload
      window.history.pushState(null, '', `#${sectionId}`);
    }
    setIsMenuOpen(false);
  };

  const handleSignUpClick = () => {
    openSignUpModal();
    setIsMenuOpen(false);
  };

  const handleJobListingClick = () => {
    openJobListingModal();
    setIsMenuOpen(false);
  };

  return (
    <header className="fixed top-0 left-0 right-0 z-50 bg-white shadow-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
        <div className="flex items-center justify-between">
          <a href="/" className="flex items-center space-x-2" aria-label="ESLTeachers.ph Home">
            <LazyImage 
              src="images/esl_logo_64.png"
              srcSet="images/esl_logo_32.webp 32w, images/esl_logo_64.webp 64w, images/esl_logo_32.png 32w, images/esl_logo_64.png 64w"
              sizes="(max-width: 640px) 32px, 64px"
              alt="ESLTeachers.ph Logo" 
              className="h-8 w-auto" 
              onError={(e) => console.error("Header logo error:", e)}
              width={64}
              height={64}
              loading="eager"
            />
            <span className="text-2xl font-bold">
              <span className="text-green-600">ESL</span>
              <span className="text-green-600">Teachers</span>
              <span className="text-green-600">.ph</span>
            </span>
          </a>
          <div className="hidden md:flex items-center">
            <nav className="mr-8">
              <ul className="flex items-center space-x-8">
                {[
                  { name: 'Home', id: 'hero' },
                  { name: 'Jobs', id: 'featured-jobs' },
                  { name: 'Benefits', id: 'benefits' },
                  { name: 'How it works', id: 'how-it-works' },
                  { name: 'Contact us', id: 'footer' }
                ].map((item) => (
                  <li key={item.name}>
                    <button
                      onClick={() => scrollToSection(item.id)}
                      className="text-lg font-medium text-gray-600 hover:text-green-600 transition-colors"
                      aria-label={`Scroll to ${item.name} section`}
                    >
                      {item.name}
                    </button>
                  </li>
                ))}
              </ul>
            </nav>
            <div className="flex items-center space-x-4">
              <Button 
                onClick={handleJobListingClick} 
                className="bg-white text-green-600 border border-green-600 hover:bg-green-50 text-sm px-4 py-2"
                aria-label="Post job listing"
              >
                Post job listing
              </Button>
              <Button 
                onClick={handleSignUpClick} 
                className="bg-green-600 text-white hover:bg-green-700"
                aria-label="Sign up now"
              >
                Sign up now
              </Button>
            </div>
          </div>
          <button
            className="md:hidden text-gray-600 hover:text-green-600 focus:outline-none"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            aria-label={isMenuOpen ? "Close menu" : "Open menu"}
          >
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div>
        <nav className={`${isMenuOpen ? 'block' : 'hidden'} md:hidden mt-4`}>
          <ul className="space-y-2">
            {[
              { name: 'Home', id: 'hero' },
              { name: 'Jobs', id: 'featured-jobs' },
              { name: 'Benefits', id: 'benefits' },
              { name: 'How it works', id: 'how-it-works' },
              { name: 'Contact us', id: 'footer' }
            ].map((item) => (
              <li key={item.name}>
                <button
                  onClick={() => scrollToSection(item.id)}
                  className="block w-full text-left text-lg font-medium text-gray-600 hover:text-green-600 transition-colors"
                  aria-label={`Scroll to ${item.name} section`}
                >
                  {item.name}
                </button>
              </li>
            ))}
            <li className="mt-4">
              <Button 
                onClick={handleJobListingClick} 
                className="w-full bg-white text-green-600 border border-green-600 hover:bg-green-50 text-sm px-4 py-2"
                aria-label="Post job listing"
              >
                Post job listing
              </Button>
            </li>
            <li className="mt-2">
              <Button 
                onClick={handleSignUpClick} 
                className="w-full bg-green-600 text-white hover:bg-green-700"
                aria-label="Sign up now"
              >
                Sign up now
              </Button>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;