import React from 'react';

function Testimonials() {
  return (
    <section className="py-20 bg-white" aria-labelledby="testimonials-title">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 id="testimonials-title" className="text-3xl font-bold text-center mb-16 text-gray-900">Success stories</h2>
        <ul className="grid gap-8 md:grid-cols-3 list-none">
          {[
            { 
              name: "Cathy B.", 
              experience: "Experience: 1 year as an ESL teacher",
              certification: "Certification: TEFL certified",
              quote: "Finding a reliable ESL teaching job was so challenging. Overwhelmed na ako sa dami ng postings pero walang fit sa akin. Thanks to ESLTeachers.ph, I found a part-time position that perfectly fits my schedule. Salamat po talaga!" 
            },
            { 
              name: "Jessa M.", 
              experience: "Experience: 2 years as an ESL teacher",
              certification: "Certification: TEFL certified",
              quote: "I was looking for an ESL company that offered competitive pay and flexible hours. ESLTeachers.ph connected me with a reputable employer within days. The application process was smooth, and now I'm earning more while working less. Highly recommend!" 
            },
            { 
              name: "Ana O.", 
              experience: "Experience: Beginner ESL teacher",
              certification: "Certification: None",
              quote: "Hello po! Gusto ko po sana mag-start mag-ESL teaching pero wala akong experience. Through ESLTeachers.ph, I found a company that values my passion for teaching over formal qualifications. Now, I'm gaining experience and loving my new job!" 
            },
            { 
              name: "Eljan W.", 
              experience: "Experience: 5 years as an ESL teacher",
              certification: "Certification: TESOL certified",
              quote: "As an ESL teacher with years of experience, I was tired of unreliable employers and delayed payments. ESLTeachers.ph matched me with a top-tier company that values my work and pays on time through GCash. It's been a game-changer for my career." 
            },
            { 
              name: "Princess C.", 
              experience: "Experience: 5 years as an ESL teacher",
              certification: "Certification: TEFL certified",
              quote: "After almost 5 years of ESL teaching, I was seeking better opportunities. ESLTeachers.ph provided me with multiple job offers that matched my experience and salary expectations. The platform made the job search so much easier!" 
            },
            { 
              name: "Janna M.", 
              experience: "Experience: High school graduate",
              certification: "Certification: None",
              quote: "Meron po bang ESL company na tumatanggap ng HS grad? Thanks to ESLTeachers.ph, the answer is yes! I found a company that values my passion for teaching over formal qualifications. I'm so grateful for this opportunity." 
            }
          ].map((testimonial, index) => (
            <li key={index} className="bg-white p-8 rounded-lg shadow-md">
              <div className="flex mb-4">
                {[...Array(5)].map((_, i) => (
                  <svg key={i} className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                  </svg>
                ))}
              </div>
              <p className="text-gray-600 mb-6">"{testimonial.quote}"</p>
              <p className="font-bold text-gray-900">{testimonial.name}</p>
              <p className="text-sm text-gray-500 mt-2">{testimonial.experience}</p>
              <p className="text-sm text-gray-500">{testimonial.certification}</p>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}

export default Testimonials;