import React from 'react';

const Button = ({ children, className, ...props }) => (
  <button 
    className={`px-6 py-3 rounded-md text-lg font-semibold transition-colors 
    ${className.includes('bg-white') ? 'bg-white text-green-600 hover:bg-gray-100' : 'bg-green-600 text-white hover:bg-green-700'} 
    ${className}`}
    {...props}
  >
    {children}
  </button>
);

export default Button;