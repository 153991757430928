import React from 'react';

function HowItWorks() {
  return (
    <section className="py-20 bg-gray-50" aria-labelledby="how-it-works-title">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 id="how-it-works-title" className="text-3xl font-bold text-center mb-16 text-gray-900">How it works</h2>
        <ol className="grid gap-8 md:grid-cols-3 list-none">
          {[
            {
              title: "Sign up and create your profile",
              description: "Share your experience, certifications, and job preferences to help us match you with the best opportunities."
            },
            {
              title: "Browse personalized job listings",
              description: "Access a curated list of positions that meet your criteria from our network of trusted ESL employers."
            },
            {
              title: "Apply and get hired",
              description: "Submit applications directly through our platform and manage your job search all in one place."
            }
          ].map((step, index) => (
            <li key={index} className="flex flex-col items-center text-center">
              <div className="rounded-full bg-green-600 text-white w-16 h-16 flex items-center justify-center mb-6 text-2xl font-bold">
                {index + 1}
              </div>
              <h3 className="text-xl font-bold mb-4 text-gray-900">{step.title}</h3>
              <p className="text-gray-600">{step.description}</p>
            </li>
          ))}
        </ol>
      </div>
    </section>
  );
}

export default HowItWorks;