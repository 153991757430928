import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Hero from './components/Hero';
import FeaturedJobs from './components/FeaturedJobs';
import ComparisonSection from './components/ComparisonSection';
import BenefitsSection from './components/BenefitsSection';
import HowItWorks from './components/HowItWorks';
import Testimonials from './components/Testimonials';
import FAQ from './components/FAQ';
import CTA from './components/CTA';
import Footer from './components/Footer';
import Modal from './components/Modal';
import NotFound from './components/NotFound';

function App() {
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);
  const [isJobListingModalOpen, setIsJobListingModalOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const openSignUpModal = () => {
    if (isMobile) {
      window.open('https://form.typeform.com/to/tLtIZDSl', '_blank');
    } else {
      setIsSignUpModalOpen(true);
    }
  };

  const openJobListingModal = () => {
    if (isMobile) {
      window.open('https://form.typeform.com/to/WoZq52C8', '_blank');
    } else {
      setIsJobListingModalOpen(true);
    }
  };

  const closeSignUpModal = () => setIsSignUpModalOpen(false);
  const closeJobListingModal = () => setIsJobListingModalOpen(false);

  const Home = () => (
    <>
      <section id="hero" className="section-spacing">
        <Hero openModal={openSignUpModal} />
      </section>
      <section id="featured-jobs" className="section-spacing">
        <FeaturedJobs openModal={openSignUpModal} />
      </section>
      <section id="comparison" className="section-spacing">
        <ComparisonSection />
      </section>
      <section id="benefits" className="section-spacing">
        <BenefitsSection />
      </section>
      <section id="how-it-works" className="section-spacing">
        <HowItWorks />
      </section>
      <section id="testimonials" className="section-spacing">
        <Testimonials />
      </section>
      <section id="faq" className="section-spacing">
        <FAQ />
      </section>
      <section id="cta" className="section-spacing">
        <CTA openModal={openSignUpModal} />
      </section>
    </>
  );

  return (
    <Router>
      <div className="flex flex-col min-h-screen bg-white">
        <Helmet>
          <title>ESLTeachers.ph | Online ESL Teaching Jobs in the Philippines</title>
          <meta name="description" content="Connect with verified employers in the Philippines for online ESL teaching jobs. Discover and secure roles that fit your skills, schedule, and salary needs." />
          <link rel="canonical" href="https://eslteachers.ph" />
        </Helmet>
        <Header openSignUpModal={openSignUpModal} openJobListingModal={openJobListingModal} isMobile={isMobile} />
        <main className="flex-1 pt-16">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
        <Footer openJobListingModal={openJobListingModal} />
        <Modal 
          isOpen={isSignUpModalOpen} 
          onClose={closeSignUpModal}
          title="Sign up now"
          typeformId="01J8S9SGNRK5YJQ8FTDR4HZZ31"
        />
        <Modal 
          isOpen={isJobListingModalOpen} 
          onClose={closeJobListingModal}
          title="Post job listing"
          typeformId="01J8SFWG1SEWS9MN5D4WZJ1SX8"
        />
      </div>
    </Router>
  );
}

export default App;