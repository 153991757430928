import React from 'react';

function LazyImage({ src, srcSet, sizes, alt, className, onError, width, height, title, loading = "lazy" }) {
  return (
    <img
      src={src}
      srcSet={srcSet}
      sizes={sizes}
      alt={alt}
      title={title || alt} // Use alt as fallback if title is not provided
      className={className}
      onError={onError}
      loading={loading}
      width={width}
      height={height}
    />
  );
}

export default LazyImage;