import React from 'react';
import Button from './Button';

function CTA({ openModal }) {
  return (
    <section className="py-20 bg-green-600 text-white shadow-lg" aria-label="Call to action">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <h2 className="text-4xl font-bold mb-8">Secure your next role as an ESL teacher</h2>
        <p className="text-xl mb-12">Set up your application today, start teaching online tomorrow.</p>
        <Button onClick={openModal} className="bg-white text-green-600 hover:bg-gray-100 shadow-md">Sign up now</Button>
      </div>
    </section>
  );
}

export default CTA;