import React from 'react';
import JobCard from './JobCard';

function FeaturedJobs({ openModal }) {
  const featuredJobs = [
    {
      title: "Online ESL teacher",
      company: "Uni English",
      location: "Remote (Philippines)",
      salary: "PHP 30,000 - PHP 50,000 per month",
      type: "Full Time",
      recruiterStatus: "Recruiter was hiring 2 weeks ago",
      applyBefore: "Apply before October 15, 2024"
    },
    {
      title: "Part-time ESL instructor",
      company: "99Friend",
      location: "Remote (Philippines)",
      salary: "PHP 15,000 - PHP 25,000 per month",
      type: "Part Time",
      recruiterStatus: "Recruiter was hiring 1 week ago",
      applyBefore: "Apply before October 7, 2024"
    },
    {
      title: "Home-based ESL tutor",
      company: "Spiiker English",
      location: "Remote (Philippines)",
      salary: "PHP 20,000 - PHP 35,000 per month",
      type: "Part Time",
      recruiterStatus: "Recruiter was hiring 3 weeks ago",
      applyBefore: "Apply before October 23, 2024"
    },
    {
      title: "Full-time online ESL teacher",
      company: "51Talk ESL",
      location: "Remote (Philippines)",
      salary: "PHP 40,000 - PHP 60,000 per month",
      type: "Full Time",
      recruiterStatus: "Recruiter was hiring 4 weeks ago",
      applyBefore: "Apply before October 31, 2024"
    },
    {
      title: "Flexible ESL teaching positions",
      company: "Vee Tutorial",
      location: "Remote (Philippines)",
      salary: "PHP 18,000 - PHP 28,000 per month",
      type: "Part Time",
      recruiterStatus: "Recruiter was hiring 2 weeks ago",
      applyBefore: "Apply before October 19, 2024"
    },
    {
      title: "Experienced ESL teacher wanted",
      company: "PJLink Language Inc.",
      location: "Remote (Philippines)",
      salary: "PHP 25,000 - PHP 45,000 per month",
      type: "Full Time",
      recruiterStatus: "Recruiter was hiring 1 week ago",
      applyBefore: "Apply before October 11, 2024"
    },
    {
      title: "Online English tutor",
      company: "Anglitlaam",
      location: "Remote (Philippines)",
      salary: "PHP 22,000 - PHP 38,000 per month",
      type: "Part Time",
      recruiterStatus: "Recruiter was hiring 2 weeks ago",
      applyBefore: "Apply before October 20, 2024"
    },
    {
      title: "ESL teacher for Vietnamese students",
      company: "SpeakWell Academy",
      location: "Remote (Philippines)",
      salary: "PHP 18,000 - PHP 30,000 per month",
      type: "Part Time",
      recruiterStatus: "Recruiter was hiring 3 weeks ago",
      applyBefore: "Apply before October 18, 2024"
    },
    {
      title: "Weekend ESL teacher",
      company: "FutureLearn ESL",
      location: "Remote (Philippines)",
      salary: "PHP 20,000 - PHP 34,000 per month",
      type: "Part Time",
      recruiterStatus: "Recruiter was hiring 1 week ago",
      applyBefore: "Apply before October 14, 2024"
    },
    {
      title: "Online ESL tutor (evening shift)",
      company: "EduConnect",
      location: "Remote (Philippines)",
      salary: "PHP 28,000 - PHP 42,000 per month",
      type: "Part Time",
      recruiterStatus: "Recruiter was hiring 2 weeks ago",
      applyBefore: "Apply before October 20, 2024"
    },
    {
      title: "Remote English teacher",
      company: "TalkAcademy",
      location: "Remote (Philippines)",
      salary: "PHP 32,000 - PHP 50,000 per month",
      type: "Full Time",
      recruiterStatus: "Recruiter was hiring 3 weeks ago",
      applyBefore: "Apply before October 28, 2024"
    },
    {
      title: "Home-based part-time ESL teacher",
      company: "TeachEZ",
      location: "Remote (Philippines)",
      salary: "PHP 15,000 - PHP 27,000 per month",
      type: "Part Time",
      recruiterStatus: "Recruiter was hiring 1 week ago",
      applyBefore: "Apply before October 13, 2024"
    },
    {
      title: "Full-time English language instructor",
      company: "SpeakUp ESL",
      location: "Remote (Philippines)",
      salary: "PHP 38,000 - PHP 55,000 per month",
      type: "Full Time",
      recruiterStatus: "Recruiter was hiring 4 weeks ago",
      applyBefore: "Apply before October 30, 2024"
    },
    {
      title: "Experienced ESL instructor",
      company: "LinguaOnline",
      location: "Remote (Philippines)",
      salary: "PHP 24,000 - PHP 40,000 per month",
      type: "Full Time",
      recruiterStatus: "Recruiter was hiring 2 weeks ago",
      applyBefore: "Apply before October 16, 2024"
    },
    {
      title: "Weekend only ESL tutor",
      company: "FlexiTeach",
      location: "Remote (Philippines)",
      salary: "PHP 12,000 - PHP 20,000 per month",
      type: "Part Time",
      recruiterStatus: "Recruiter was hiring 1 week ago",
      applyBefore: "Apply before October 12, 2024"
    }
  ];

  return (
    <section className="pt-11 pb-20 bg-white" aria-labelledby="featured-jobs-title">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 id="featured-jobs-title" className="text-3xl font-bold text-center mb-12 text-gray-900">Featured jobs this month</h2>
        <ul className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3 list-none">
          {featuredJobs.map((job, index) => (
            <li key={index}>
              <JobCard job={job} openModal={openModal} />
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}

export default FeaturedJobs;