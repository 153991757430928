import React from 'react';

function FAQ() {
  return (
    <section className="py-20 bg-gray-50" aria-labelledby="faq-title">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 id="faq-title" className="text-3xl font-bold text-center mb-16 text-gray-900">Frequently asked questions</h2>
        <dl className="grid gap-8 md:grid-cols-2">
          {[
            { 
              q: "Do I need prior ESL teaching experience to use this platform?", 
              a: "No, we offer positions for both experienced teachers and newcomers. Simply set your experience level in your profile, and we'll match you accordingly." 
            },
            { 
              q: "Do I need to pay to apply through this platform?", 
              a: "No, applying through our platform is completely free. There are no hidden fees or charges, so you can browse and apply to jobs with confidence." 
            },
            { 
              q: "Are there opportunities for part-time or flexible schedules?", 
              a: "Absolutely! Many of our employers offer part-time positions and flexible hours to fit your availability." 
            },
            { 
              q: "Do I need a teaching certificate or degree?", 
              a: "While some positions require certifications like TEFL or TESOL, we have many listings that accept undergraduates and high school graduates." 
            },
            { 
              q: "How will I receive payments from employers?", 
              a: "Payment methods vary by employer. Details are provided in each job listing and can be discussed during the hiring process. Many offer convenient options like GCash." 
            },
            { 
              q: "Can I teach remotely from home?", 
              a: "Yes, many employers on our platform offer home-based teaching positions, allowing you to work from the comfort of your home." 
            }
          ].map((faq, index) => (
            <div key={index}>
              <dt className="text-xl font-bold mb-4 text-gray-900">{faq.q}</dt>
              <dd className="text-gray-600">{faq.a}</dd>
            </div>
          ))}
        </dl>
      </div>
    </section>
  );
}

export default FAQ;