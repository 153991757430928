import React from 'react';
import Button from './Button';
import LazyImage from './LazyImage';

function Hero({ openModal }) {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "eslteachers.ph",
    "url": "https://eslteachers.ph",
    "description": "Connect with verified employers in the Philippines for online ESL jobs that fit your skills, schedule, and salary needs."
  };

  const handleImageError = (error) => {
    console.error("Failed to load hero image:", error);
  };

  return (
    <section className="py-20 md:py-32 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
          <div className="space-y-8">
            <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold text-gray-900 leading-tight">
              Land online ESL jobs through curated listings
            </h1>
            <p className="text-xl text-gray-600">
            Connect with verified employers in the Philippines for online ESL teaching jobs. Discover and secure roles that fit your skills, schedule, and salary needs.
            </p>
            <Button onClick={openModal} className="bg-green-600 text-white hover:bg-green-700 text-lg px-8 py-3">
              Sign up now
            </Button>
          </div>
          <div className="aspect-w-4 aspect-h-3 rounded-lg overflow-hidden shadow-xl mt-8 md:mt-0">
            <LazyImage 
              src="images/hero-image-600w.png"
              srcSet="images/hero-image-480w.webp 480w, images/hero-image-600w.webp 600w, images/hero-image-800w.webp 800w, images/hero-image-1200w.webp 1200w, images/hero-image-480w.png 480w, images/hero-image-600w.png 600w, images/hero-image-800w.png 800w, images/hero-image-1200w.png 1200w"
              sizes="(max-width: 480px) 480px, (max-width: 1024px) 600px, (max-width: 1200px) 800px, 1200px"
              alt="ESL Teacher working from home" 
              title="ESL Teacher working from home"
              className="object-cover w-full h-full" 
              onError={(e) => console.error("Hero image error:", e)}
              width={1200}
              height={900}
              loading="eager" // Load immediately as it's above the fold
            />
          </div>
        </div>
      </div>
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
    </section>
  );
}

export default Hero;