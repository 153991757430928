import React from 'react';
import { Link } from 'react-router-dom';

function NotFound() {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <h1 className="text-6xl font-bold text-green-600 mb-4">404</h1>
      <p className="text-2xl text-gray-700 mb-8">Oops! Page not found.</p>
      <p className="text-lg text-gray-600 mb-8">The page you're looking for doesn't exist or has been moved.</p>
      <Link to="/" className="bg-green-600 text-white px-6 py-3 rounded-lg hover:bg-green-700 transition-colors">
        Go back to homepage
      </Link>
    </div>
  );
}

export default NotFound;