import React from 'react';

function ComparisonSection() {
  return (
    <section className="py-20 bg-gray-50" aria-labelledby="comparison-title">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 id="comparison-title" className="text-3xl font-bold text-center mb-16 text-gray-900">Find the right opportunities</h2>
        
        <div className="grid gap-12 md:grid-cols-2">
          <article className="bg-white rounded-lg shadow-md p-8">
            <h3 className="text-2xl font-bold mb-8 text-gray-900">Traditional job platforms</h3>
            <ul className="space-y-8 list-none">
              {[
                {
                  title: "Countless of unreliable listings",
                  description: "In platforms and online groups with unverified job postings, it's difficult to identify reputable ESL companies and sort through decent options."
                },
                {
                  title: "Strict schedules and limited options",
                  description: "Struggling to find positions that offer the flexibility you need? Fixed schedules make it difficult to balance work with your personal life."
                },
                {
                  title: "Unclear payment terms and delayed compensation",
                  description: "You're often left in the dark about payment rates and methods. Sometimes they're even late. The lack of transparency makes it hard to plan your monthly finances."
                }
              ].map((item, index) => (
                <li key={index} className="flex items-start">
                  <div className="flex-shrink-0 mt-1">
                    <svg className="h-5 w-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <div className="ml-3">
                    <h4 className="text-lg font-semibold text-gray-900">{item.title}</h4>
                    <p className="mt-2 text-gray-600">{item.description}</p>
                  </div>
                </li>
              ))}
            </ul>
          </article>
          
          <article className="bg-white rounded-lg shadow-md p-8">
            <h3 className="text-2xl font-bold mb-8 text-gray-900">ESLTeachers.ph</h3>
            <ul className="space-y-8 list-none">
              {[
                {
                  title: "Verified ESL employers",
                  description: "We partner only with vetted, reputable ESL employers to ensure you find reliable opportunities."
                },
                {
                  title: "Flexible schedules that work for you",
                  description: "Choose from a variety of positions offering flexible hours that align with your lifestyle. Whether you prefer teaching in the morning, evening, or on weekends, we have options that suit your needs."
                },
                {
                  title: "Transparent pay and timely payments",
                  description: "Access opportunities with competitive rates stated upfront. Enjoy consistent and reliable payments via GCash and local bank transfers."
                }
              ].map((item, index) => (
                <li key={index} className="flex items-start">
                  <div className="flex-shrink-0 mt-1">
                    <svg className="h-5 w-5 text-green-500" fill="currentColor" viewBox="0 0 20 20">
                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L8.586 10l-1.293 1.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <div className="ml-3">
                    <h4 className="text-lg font-semibold text-gray-900">{item.title}</h4>
                    <p className="mt-2 text-gray-600">{item.description}</p>
                  </div>
                </li>
              ))}
            </ul>
          </article>
        </div>
      </div>
    </section>
  );
}

export default ComparisonSection;