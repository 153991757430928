import React, { useEffect, useCallback, useState } from 'react';

function Modal({ isOpen, onClose, title, typeformId }) {
  const [isMobile, setIsMobile] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust this breakpoint as needed
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      if (window.innerWidth <= 768) {
        window.open(`https://form.typeform.com/to/${typeformId}`, '_blank');
        onClose();
      } else {
        const script = document.createElement('script');
        script.src = "//embed.typeform.com/next/embed.js";
        script.async = true;
        script.onload = () => {
          // Set a small timeout to allow Typeform to initialize
          setTimeout(() => setIsLoading(false), 1000);
        };
        document.body.appendChild(script);

        return () => {
          document.body.removeChild(script);
        };
      }
    }
  }, [isOpen, isMobile, onClose, typeformId]);

  const handleOutsideClick = useCallback((e) => {
    if (e.target.classList.contains('modal-overlay')) {
      onClose();
    }
  }, [onClose]);

  if (!isOpen || isMobile) return null;

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center modal-overlay"
      onClick={handleOutsideClick}
    >
      <div className="bg-white rounded-lg shadow-xl w-full max-w-4xl mx-4 h-[90vh] flex flex-col">
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-2xl font-bold text-gray-800">{title}</h2>
          <button 
            onClick={onClose}
            className="text-gray-400 hover:text-gray-600 transition-colors"
          >
            <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div className="flex-grow overflow-hidden relative">
          {isLoading && (
            <div className="absolute inset-0 flex items-center justify-center bg-white">
              <div className="flex space-x-2 justify-center items-center">
                {[...Array(3)].map((_, i) => (
                  <div key={i} className="w-3 h-3 bg-green-600 rounded-full animate-pulse" style={{animationDelay: `${i * 0.15}s`}}></div>
                ))}
              </div>
            </div>
          )}
          <div className="h-full" data-tf-live={typeformId}></div>
        </div>
      </div>
    </div>
  );
}

export default Modal;