import React from 'react';
import LazyImage from './LazyImage';

function Footer({ openJobListingModal }) {
  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      const headerOffset = 80; // Adjust this value based on your header height
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  return (
    <footer id="footer" className="bg-gray-900 text-white py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-4">
          <div>
            <a href="/" className="flex items-center space-x-2">
              <LazyImage 
                src="images/esl_logo_white_64.png"
                srcSet="images/esl_logo_white_32.webp 32w, images/esl_logo_white_64.webp 64w, images/esl_logo_white_32.png 32w, images/esl_logo_white_64.png 64w"
                sizes="(max-width: 640px) 32px, 64px"
                alt="ESLTeachers.ph Logo" 
                className="h-8 w-auto" 
                onError={(e) => console.error("Footer logo error:", e)}
                width={64}
                height={64}
              />
              <span className="text-2xl font-bold text-white">ESLTeachers.ph</span>
            </a>
            <p className="mt-4 text-gray-400">
              © 2024 eslteachers.ph<br />
              All rights reserved.
            </p>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">Quick links</h3>
            <ul className="space-y-2">
              {[
                { name: 'Home', id: 'hero' },
                { name: 'Jobs', id: 'featured-jobs' },
                { name: 'Benefits', id: 'benefits' },
                { name: 'How it works', id: 'how-it-works' },
                { name: 'Contact us', id: 'footer' }
              ].map((item) => (
                <li key={item.name}>
                  <button
                    onClick={() => scrollToSection(item.id)}
                    className="text-gray-400 hover:text-white transition-colors"
                  >
                    {item.name}
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">For companies</h3>
            <button
              onClick={openJobListingModal}
              className="text-gray-400 hover:text-white transition-colors"
            >
              Post job listing
            </button>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">Contact information</h3>
            <p className="text-gray-400">Email: support@eslteachers.ph</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;