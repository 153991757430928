import React from 'react';

function BenefitsSection() {
  return (
    <section className="py-20 bg-white" aria-labelledby="benefits-title">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 id="benefits-title" className="text-3xl font-bold text-center mb-16 text-gray-900">Why choose ESLTeachers.ph?</h2>
        <ul className="grid gap-12 sm:grid-cols-2 md:grid-cols-3 list-none">
          {[
            {
              title: "Opportunities for all experience levels",
              description: "Whether you're a seasoned teacher or just starting out, we have positions that suit all experience levels, including roles that don't require certifications."
            },
            {
              title: "Secure and scam-free platform",
              description: "Our platform screens employers to protect you from fraudulent offers, ensuring a safe job-hunting experience."
            },
            {
              title: "Personalized job matching",
              description: "Our advanced matching algorithm considers your skills, experience, and preferences to find the best job opportunities for you."
            }
          ].map((benefit, index) => (
            <li key={index} className="bg-green-50 rounded-lg p-6">
              <div className="flex items-center mb-4">
                <div className="rounded-full bg-green-100 p-2 mr-4">
                  <svg className="h-6 w-6 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                </div>
                <h3 className="text-xl font-bold text-gray-900">{benefit.title}</h3>
              </div>
              <p className="text-gray-600">{benefit.description}</p>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}

export default BenefitsSection;